import {
  BotPatchedCatalogEntity,
  Icons,
  PageData,
} from '@internal/plugin-sl-assets';

export const PortalVersionStatus = Object.freeze({
  HF: 'On Hf Branch',
  UP_TO_DATE: 'Up to date',
  OUTDATED: 'Outdated',
  ERRORED: 'Error',
});

const AllEnvs = [
  'production',
  'staging',
  'development',
  'e2e-development',
] as const;

const AllServices = ['strat'] as const;

export type Env = (typeof AllEnvs)[number];

export type Service = (typeof AllServices)[number];

export const VersionAvailableForServices = {
  strat: ['production', 'staging', 'development', 'e2e-development'],
};

export type Props = {
  pageData: PageData;
};

export const StatusToIcon = Object.freeze({
  [PortalVersionStatus.UP_TO_DATE]: Icons.CHECK_CIRCLE,
  [PortalVersionStatus.OUTDATED]: Icons.SYNC_PROBLEM,
  [PortalVersionStatus.ERRORED]: Icons.CLOSE,
  [PortalVersionStatus.HF]: Icons.HOT,
});

export const StatusToColor = Object.freeze({
  [PortalVersionStatus.UP_TO_DATE]: 'primary',
  [PortalVersionStatus.OUTDATED]: 'fuchsia',
  [PortalVersionStatus.ERRORED]: 'error',
  [PortalVersionStatus.HF]: 'orange',
});

export const StatusToBadgeColor = Object.freeze({
  [PortalVersionStatus.UP_TO_DATE]: 'green',
  [PortalVersionStatus.OUTDATED]: 'fuchsia',
  [PortalVersionStatus.ERRORED]: 'error',
  [PortalVersionStatus.HF]: 'orange',
});

export const getResultTitle = (result: BotPatchedCatalogEntity) => {
  const titleSplit = result.metadata.title.split(' ');

  return titleSplit.slice(1, titleSplit.length - 1).join(' ');
};

export const isOnHfBranch = (result: BotPatchedCatalogEntity) =>
  ((result.botsResults?.branch || '') as string).includes('hotfix');

export const getDisplayColor = (result: BotPatchedCatalogEntity) =>
  StatusToColor[
    (result.botsResults
      ?.status as (typeof PortalVersionStatus)[keyof typeof PortalVersionStatus]) ||
      PortalVersionStatus.ERRORED
  ];

export const getBadgeColor = (result: BotPatchedCatalogEntity) =>
  StatusToBadgeColor[
    (result.botsResults
      ?.status as (typeof PortalVersionStatus)[keyof typeof PortalVersionStatus]) ||
      PortalVersionStatus.ERRORED
  ];
