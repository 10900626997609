import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
  useApi,
} from '@backstage/core-plugin-api';
import { Bots, SLBotsApi } from '@internal/plugin-sl-bots';

export class SLOnCallApi extends SLBotsApi {
  constructor(
    readonly discoveryApi: DiscoveryApi,
    readonly identityApi: IdentityApi,
  ) {
    super(discoveryApi, identityApi, Bots.ONCALL);
  }

  getAvailability(page = 0): Promise<Response> {
    return this.get(`/api/availability/?page=${page}`, {
      'Content-Type': 'application/json',
    });
  }

  getAvailabilityStats(page = 0): Promise<Response> {
    return this.get(`/api/availability/stats?page=${page}`, {
      'Content-Type': 'application/json',
    });
  }

  getSummary(withPdf: boolean): Promise<Response> {
    return this.get(`/api/summary/?pdf=${withPdf ? 1 : 0}`);
  }

  getExpectations(page = 0): Promise<Response> {
    return this.get(`/api/expectations/?page=${page}`, {
      'Content-Type': 'application/json',
    });
  }

  patchAvailability(data: BodyInit): Promise<Response> {
    return this.patch(`/api/availability/`, JSON.stringify(data), {
      'Content-Type': 'application/json',
    });
  }

  getSchedule(): Promise<Response> {
    return this.get('/api/schedule/', {
      'Content-Type': 'application/json',
    });
  }

  patchSchedule(date: string): Promise<Response> {
    return this.patch('/api/schedule/', JSON.stringify({ date }), {
      'Content-Type': 'application/json',
    });
  }

  adminUrl(entityName: string): Promise<Response> {
    return this.get(`/api/shortcuts/admin/${entityName}/`);
  }

  cableAdminUrl(entityName: string): Promise<Response> {
    return this.get(`/api/shortcuts/cableAdmin/${entityName}/`);
  }

  botsAdminUrl(entityName: string): Promise<Response> {
    return this.get(`/api/shortcuts/botsAdmin/${entityName}/`);
  }

  today(): Promise<Response> {
    return this.get('/api/shortcuts/shifts/today');
  }

  releaseWizardtoday(): Promise<Response> {
    return this.get('/api/shortcuts/releaseWizard/today');
  }

  myShifts(): Promise<Response> {
    return this.get(`/api/shortcuts/user/shifts/`);
  }

  shiftCompensation(): Promise<Response> {
    return this.get(`/api/shortcuts/user/compensation`);
  }

  setDefaultNotificationRules(email: string): Promise<Response> {
    return this.post(
      '/api/shortcuts/defaultOpsgenieNotifications/',
      JSON.stringify({ email }),
      {
        'Content-Type': 'application/json',
      },
    );
  }

  getCalendarAccess(): Promise<Response> {
    return this.get(`/api/calendar/access/`);
  }
}

export const SLOnCallApiRef = createApiRef<SLOnCallApi>({
  id: 'plugin.sl-oncall.api',
});

export const useOnCallApi = () => {
  return useApi<SLOnCallApi>(SLOnCallApiRef);
};
