import { EnvironmentNames, switchEnvironment } from './environments';
import React from 'react';
import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';

type Experiment = {
  pathNamePrefix: string;
  active: boolean;
};

export const EXPERIMENTS: Record<string, Experiment> = {
  'ci-jobs': {
    pathNamePrefix: '/qa/ci-jobs',
    active: true,
  }
};

export const Experiments = () => {
  const featureFlagsApi = useApi(featureFlagsApiRef);

  React.useEffect(() => {
    for (const experimentKey in EXPERIMENTS) {
      if (
        EXPERIMENTS[experimentKey].active &&
        window.location.pathname.startsWith(
          EXPERIMENTS[experimentKey].pathNamePrefix,
        )
      ) {
        if (
          featureFlagsApi.isActive(
            `experiment-${experimentKey}-${EnvironmentNames.LOCAL}-redirect`,
          )
        ) {
          switchEnvironment(EnvironmentNames.LOCAL);
        }
        if (
          featureFlagsApi.isActive(
            `experiment-${experimentKey}-${EnvironmentNames.DEVELOPMENT}-redirect`,
          )
        ) {
          switchEnvironment(EnvironmentNames.DEVELOPMENT);
        }
      }
    }
  }, [featureFlagsApi]);

  return null;
};
