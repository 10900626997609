import {
  CommandNames,
  CommandValue,
} from '@internal/backstage-plugin-sl-common';
import {
  adminRedirectFactory,
  botsAdminRedirectFactory,
  cableAdminRedirectFactory,
  DefaultOpsgenieNotifications,
  ShiftCompensation,
  tubescreamerAdSearchFactory,
} from '@internal/plugin-sl-oncall';
import { CommitTracker } from '@internal/plugin-sl-tools';
import { deployToFactory } from '@internal/plugin-sl-release';
import { olxAdSearchFactory, tubescreamerDevAdSearchFactory } from './maple';
import { githubPrFactory } from './github';

export const Commands: Record<CommandValue, any> = Object.freeze({
  [CommandNames.GITHUB_PR_BY_ID]: githubPrFactory,
  [CommandNames.TUBESCREAMER_AD_SEARCH_DEV]: tubescreamerDevAdSearchFactory,
  [CommandNames.TUBESCREAMER_AD_SEARCH]: tubescreamerAdSearchFactory,
  [CommandNames.OLX_AD_SEARCH]: olxAdSearchFactory,
  [CommandNames.TOOLS_COMMIT_TRACKER]: CommitTracker,
  [CommandNames.RELEASE_DEPLOY]: deployToFactory,
  [CommandNames.ON_CALL_CABLE_ADMIN]: cableAdminRedirectFactory,
  [CommandNames.ON_CALL_BOTS_ADMIN]: botsAdminRedirectFactory,
  [CommandNames.ON_CALL_ADMIN]: adminRedirectFactory,
  [CommandNames.ON_CALL_DEFAULT_OPSGENIE_NOTIFICATIONS]:
    DefaultOpsgenieNotifications,
  [CommandNames.ON_CALL_SHIFT_COMPENSATION]: ShiftCompensation,
});
