import { GithubGroupGranting } from './types';

export const GithubGroupsGrantedPermissions: GithubGroupGranting[] = [
  {
    groupName: 'sectorlabs',
    permissionGroups: ['all', 'sl.devs'],
    permissions: [
      'sl.oncall.schedule',
      'sl.announcements.modify',
      'sl.release.deploy.next.view',
    ],
  },
  {
    groupName: 'release-wizards',
    permissionGroups: ['all', 'sl.release.wizard.maple'],
    permissions: [],
  },
  {
    groupName: 'on-call',
    permissionGroups: ['all'],
    permissions: [
      'sl.oncall.availability',
      'sl.oncall.schedule',
      'sl.oncall.summary',
      'sl.oncall.shortcuts.shifts',
      'sl.oncall.shortcuts.notifications',
      'sl.oncall.shortcuts.admin.cable',
      'sl.oncall.shortcuts.admin.bots',
      'sl.cli.login',
      'sl.cli.shortcuts.login',
    ],
  },
  {
    groupName: 'olx-mena-tech',
    permissionGroups: ['all', 'sl.devs'],
    permissions: [],
  },
  {
    groupName: 'manual-qa',
    permissionGroups: ['all'],
    permissions: [],
  },
  {
    groupName: 'sectorlabs-inters',
    permissionGroups: ['all', 'sl.devs'],
    permissions: [],
  },
  {
    groupName: 'devops',
    permissionGroups: ['all'],
    permissions: [
      'sl.oncall.shortcuts.admin.cable',
      'sl.cli.login',
      'sl.cli.shortcuts.login',
    ],
  },
  {
    groupName: 'cable',
    permissionGroups: ['all'],
    permissions: ['sl.oncall.shortcuts.admin.cable'],
  },
];
